export const LocalStorageKey = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  USER_INFO: 'user_info'
};

export const ENUM_ROLE = {
  ADMIN: 'ADMIN',
  EMPLOYEE: 'EMPLOYEE'
};

export const ENUM_TYPE_MESS = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning'
};

export const CONST_SYS = {
  PASS_RESET: '@@okvip@@'
};

export const CONST_SITE = {
  SHBET: 'SHBET',
  NEW88: 'NEW88',
  BO_789BET: 'bo_789bet',
  JUN88: 'jun88k36',
  JUN88CMD: 'jun88cmd'
};

export const LIST_SITE = [
  {
    key: CONST_SITE.SHBET,
    name: CONST_SITE.SHBET
  },
  {
    key: CONST_SITE.NEW88,
    name: CONST_SITE.NEW88
  },
  {
    key: CONST_SITE.BO_789BET,
    name: CONST_SITE.BO_789BET
  },
  {
    key: CONST_SITE.JUN88,
    name: CONST_SITE.JUN88
  },
  {
    key: CONST_SITE.JUN88CMD,
    name: CONST_SITE.JUN88CMD
  }
];
export const SETTING_KEYS = {
  TITLE: 'title',
  NO_DATA: 'no-data',
  TEXT_RUN: 'text_run',
  LINK_HOME: 'link_home',
  LINK_LOGIN: 'link_login',
  LINK_DOWNLOAD_APP: 'link_download_app',
  LINK_REGISTER: 'link_register',
  PRIZE_STRUCTURE: 'prize_structure',
  RANK_MATCH: 'rank_match'
};
