import { ConfigProvider, Layout } from 'antd';
import { Outlet } from 'react-router-dom';

const UnProtectedLayout = () => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            colorBgLayout: '#ffffff'
          }
        }
      }}
    >
      <Layout className="min-h-screen h-full" hasSider={true}>
        <Layout.Content>
          <Outlet />
        </Layout.Content>
      </Layout>
    </ConfigProvider>
  );
};

export default UnProtectedLayout;
