import { Suspense } from 'react';
import AppRoutes from './AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import Loading from 'components/Loading';

function Apps() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <AppRoutes />
        </Suspense>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default Apps;
