import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import UnProtectedLayout from 'components/layouts/UnProtectedLayout';
import { SITE } from 'config/config';
import { CONST_SITE } from 'utils/common/const';

const HomePage = lazy(() => import('pages/Home'));
const Jun88_Page = lazy(() => import('pages/JUN88'));
const Jun88CMD_Page = lazy(() => import('pages/JUN88CMD'));

const Bo_789betPage = lazy(() => import('pages/Bo_789BET'));
const PageError = lazy(() => import('pages/Error'));

const AppRoutes = () => {
  const getHomePageComponent = () => {
    switch (SITE) {
      case CONST_SITE.JUN88:
        return <Jun88_Page />;
      case CONST_SITE.JUN88CMD:
        return <Jun88CMD_Page />;
      case CONST_SITE.BO_789BET:
        return <Bo_789betPage />;
      default:
        return <HomePage />;
    }
  };
  return (
    <Routes>
      <Route path="/" element={<UnProtectedLayout />}>
        {/* <Route index element={<HomePage />} /> */}
        {/* <Route index element={SITE === CONST_SITE.NEW88 ? <New88Page /> : <HomePage />} /> */}
        <Route index element={getHomePageComponent()} />
      </Route>
      <Route path="*" element={<PageError />} />
    </Routes>
  );
};

export default AppRoutes;
